



































































































import {Component} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {mixins} from 'vue-class-component'
import QueryParserMixin, {QueryType} from '~/mixins/query-parser-mixin'
import PaginationMixin from '~/mixins/pagination-mixin'
import {DiamondLog, DiamondLogEvent} from '~/components/log/log-data'
import {LogPermission} from '~/utils/permissions'

@Component({
    components: {},
    metaInfo() {
        return {
            title: 'Diamond Log'
        }
    }
})
export default class DiamondLogTable extends mixins(QueryParserMixin, PaginationMixin) {
    static permission = LogPermission.View

    step: number = 20

    DiamondLogEvent = DiamondLogEvent

    keyword: string = ''
    type: DiamondLogEvent | '' = ''

    queryDef = [
        {localVar: 'keyword'},
        {localVar: 'type', type: QueryType.Number},
    ]

    tableData: DiamondLog[] = []

    async updateData() {
        const res = await createRequest('/shop/diamond-logs', 'get', {
            skip: (this.currentPage - 1) * this.step,
            limit: this.step,
            keyword: this.keyword,
            types: this.type
        }).send()
        this.totalCount = res.data.count
        this.tableData = res.data.diamond_logs

        await this.setQuery()
    }

    async created() {
        this.parseQuery()
        await this.setQuery(false)
        await this.updateData()
    }

    get diamondLogEvents() {
        return Object.values(DiamondLogEvent).filter((v) => {
            return typeof v === 'number'
        })
    }
}

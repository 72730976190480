export enum DiamondLogEvent {
    HOMEWORK_GRADE_CHANGED = -5,
    TUTOR_MATCHING = -4,
    EXCHANGE_COST = -3,
    BUY_COURSE = -1,
    INIT = 0,
    REVIEW = 1,
    REFUND = 2,
    EXCHANGE_GAIN = 3,
    PURCHASE = 4,
    EVENT = 5,
    HARDWORKING = 6,
    COUPON_CODE_REDEEM = 7,
    LV_REWARD_REDEEM = 8,
    MISSION_REWARD = 9,
    // REFEREE_REGISTER = 10,
    REFEREE_PURCHASED = 11,
    REFERRED = 12,
    REIMBURSE = 13,
    HOMEWORK = 14, // MAX


    SPECIAL = 100
}

export class DiamondLog {
    _id: string = ''
    member_id: string = ''
    value: number = 0
    event: DiamondLogEvent = DiamondLogEvent.INIT
    data: object = {}
    time: number = 0

    display_name?: string
}

export class IpRecord {
    _id: {
        member_id: string;
        ip: string;
    };
    count: number;
    last: number;

    //out
    display_name?: string;
}
